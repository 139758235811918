(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/components/analytics-target-payload/assets/javascripts/send-target-payload.js') >= 0) return;  svs.modules.push('/components/components/analytics-target-payload/assets/javascripts/send-target-payload.js');

var svs = svs || {};

(function(svs) {
  'use strict';
  svs.components = svs.components || {};
  svs.components.analytics = svs.components.analytics || {};
  svs.components.analytics.sendTargetPayload = function(evt, cb) {
    if (svs.core && svs.core.target && svs.core.target.data.payload) {
      window.adobeDataLayer.adlPush('targetPayloadClick', {
        site: svs.core.analytics.adl.site,
        consent: svs.core.analytics.adl.consent,
        user: svs.core.analytics.adl.user,
        page: svs.core.analytics.adl.page,
        payload: svs.core.target.data.payload,
        mbox: evt.mbox
      });
    }
    var msg = new svs.core.log.Message(svs.core.log.MessageType.targetPayload);
    msg.pe = evt.pe;
    msg.tnta = evt.tnta;
    svs.core.log.track('svs.components.analytics', msg);

    if (typeof cb === 'function') {
      svs.core.log.flush();
      setTimeout(cb, 100);
    }
  };
})(svs);


 })(window);